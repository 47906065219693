export const WORKS = [
  {
    url: 'https://www.arte.tv/fr/',
    project: 'arte.tv',
    client: 'Arte GEIE',
    company: 'Linkvalue',
    description:
      'Développement de composant et page pour le design system du Groupe Arte en ReScript. Intégration du design system dans le site de replay de la chaîne.',
    date: '2021 - today',
    occupation: 'Développeur frontend',
    stack: [
      'reScript',
      'reactJs',
      'emotionCss',
      'html5',
      'css3',
      'jest',
      'cypress',
      'storybook',
      'nextJs',
      'typeScript',
    ],
  },
  {
    url: 'https://www.salto.fr/',
    project: 'salto.fr',
    client: 'Bedrock (Groupe M6)',
    company: 'Linkvalue',
    description:
      'Développement de la partie frontend de la plateforme de streaming vidéo',
    date: '2020 - 2021',
    occupation: 'Développeur frontend',
    stack: [
      'reactJs',
      'html5',
      'css3',
      'redux',
      'styled-components',
      'Jest',
      'cucumber',
      'vueJS',
      'cypress',
    ],
  },
  {
    url: 'http://6play.fr',
    project: '6play.fr',
    client: 'Groupe M6',
    company: 'Linkvalue',
    description:
      "Refonte graphique de la plateforme avec un design system pour intégrer plus de possibilités d'éditoralisation",
    date: '2018 - 2021',
    occupation: 'Développeur frontend',
    stack: [
      'reactJs',
      'html5',
      'css3',
      'redux',
      'styled-components',
      'Jest',
      'cucumber',
      'vueJS',
      'cypress',
    ],
  },
  {
    url: 'http://www.ebuyclub.com',
    project: 'ebuyclub.com',
    client: 'Plebicom',
    company: 'Plebicom',
    date: '2014 - 2017',
    occupation: 'Développeur FullStack / Référent frontend',
    description: '',
    stack: ['html5', 'css3', 'jQuery', 'java', 'j2ee', 'jsp'],
  },
  {
    url: 'https://shopformiles.flyingblue.com/',
    project: 'Shop For Miles (Flying Blue)',
    client: 'Air France',
    company: 'Plebicom',
    date: '2017',
    occupation: 'Intégrateur/Développeur frontend',
    description: '',
    stack: ['html5', 'css3', 'jQuery', 'java', 'j2ee', 'jsp'],
  },
  {
    url: 'https://www.bonnejournee-by-up.fr/',
    project: 'Bonne journée',
    client: 'Groupe Up',
    company: 'Plebicom',
    date: '2016 - 2017',
    occupation: 'Intégrateur/Développeur frontend',
    description: '',
    stack: ['html5', 'css3', 'jQuery', 'java', 'j2ee', 'jsp'],
  },
];

export const STUDIES = [
  {
    degree:
      "Licence Professionnelle Métiers de l'Internet et des Applications Multimédia",
    date: '2012 - 2013',
    school: 'UPMF Grenoble',
  },
  {
    degree: 'Licence Informatique',
    date: '2011 - 2012',
    school: 'UCBL Lyon',
  },
  {
    degree: 'D.U.T Informatique',
    date: '2009 - 2011',
    school: 'UCBL Lyon',
  },
];
